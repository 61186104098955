import React, { Component, createRef, Fragment } from "react";
import {
  Hero,
  Title,
  TechniqueTitle,
  ClientTag,
  CaseWrapper,
  BackButton,
  BackArrow,
} from "../styles/Case.styles";

import Reveal from "react-reveal/Reveal";
import { withRouter } from "react-router";
import Accomplish from "../components/Accomplish";
import Footer from "../components/Footer";
import { Efectr, Efectr1, Efectr2 } from "../styles/effect.styles";
import CustomSlider from "../components/SliderProjects";

export const ScrollTop = ({ children, location }) => {
  React.useEffect(() => window.scrollTo(0, 0), [location]);
  return children;
};

class Case extends Component {
  constructor(props) {
    super(props);
    this.introRef = createRef();

    this.state = {
      toBack: false,
      introTop: 0,
      hasBackground: false,
      animateCase: "",
    };
  }

  componentDidUpdate() {
    if (this.state.toBack) {
      setTimeout(() => {
        this.props.setNavBackground(false);
        this.props.history.push("/work");
      }, 400);
    }
    if (this.state.animateCase) {
      setTimeout(() => {
        this.props.setNavBackground(false);

        this.props.history.push(this.state.animateCase);
        window.scrollTo(0, 0);
      }, 400);
    }
    if (this.props.counterLimit) {
      setTimeout(() => {}, 400);
    }
  }

  render() {
    return (
      <Fragment>
        <Efectr />
        <Efectr1 />
        <Efectr2 />
        <ScrollTop>
          <CaseWrapper>
            <Reveal effect="fadeIn">
              <Hero
                className="mainhero"
                // style={{
                //   backgroundImage: "url('./img/projects/big/img1.jpg')",
                // }}
              >
                <CustomSlider
                  counterLimit="37"
                  dir="./img/projects/StTropez/ST"
                />
                <BackButton
                  className="backdetail"
                  onClick={() =>
                    this.setState({ toBack: true, hasBackground: false })
                  }
                  toBack={this.state.toBack}
                  hasBackground={this.state.hasBackground}
                >
                  <BackArrow src="./img/back.png" alt="Back to Projects" />
                  <span>Back </span>
                </BackButton>
                <div className="herocaption">
                  <ClientTag>INTERIOR</ClientTag>
                  <Title>Private Villa in St. Tropez</Title>
                  <TechniqueTitle>
                    We made the customer's dreams come true by recreating the
                    same feeling of the two already existing villas in the same
                    location by also adding some customized features.
                  </TechniqueTitle>
                </div>
              </Hero>
            </Reveal>

            <section className="container-fluid" id="detailproject">
              <div className="row m-2-hor">
                <div className="col-md-8">
                  <p className="content">
                    We utilised, for such project, resistant and durables
                    materials and gave them an elegant finish to better blend
                    with the villa enviroment.
                  </p>
                  <p className="content">
                    We chose for the interiors natural brushed Oak. For the
                    exteriors we preferred Teak Wood for his long lasting
                    resistance and style.
                  </p>
                </div>
                <div className="col-md-4 sticky">
                  <ul className="detailproject">
                    <li>
                      <span className="tile">Categories:</span>
                      <span>Custom Furnitures</span>
                    </li>
                    <li>
                      <span className="tile">Client:</span>
                      <span>Private Customer</span>
                    </li>
                    <li>
                      <span className="tile">Completed:</span>
                      <span>September 2021</span>
                    </li>
                    <li>
                      <span className="tile">Project type:</span>
                      <span>Interiors, F.F. & E. and Joinery</span>
                    </li>
                  </ul>
                </div>
                <div className="col-md-12">
                  {/* <div className="tags">
                      <span className="heading">Tags :</span>
                      <span className="content">Inspiration</span>
                      <span className="content">Decoation</span>
                      <span className="content">Interior</span>
                      <span className="content">Minimal</span>
                    </div> */}
                </div>
                <div className="col-md-12">
                  <div className="share">
                    <span className="heading">Share :</span>
                    <span className="content">
                      <i className="fa  fa-instagram"></i>
                    </span>
                  </div>
                </div>
              </div>
            </section>
          </CaseWrapper>
        </ScrollTop>

        <Reveal effect="fadeInUp">
          <Accomplish className="pt-0" />
        </Reveal>

        <Footer />
      </Fragment>
    );
  }
}

export default withRouter(Case);
