import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class CustomSlide extends Component {
  render() {
    const { index, ...props } = this.props;
    return <div {...props}></div>;
  }
}

export default class Responsive extends Component {
  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 400,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      autoplay: true,
      autoplaySpeed: 3000,
      responsive: [
        {
          breakpoint: 5000,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 1600,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
      ],
    };

    return (
      <div className="slick slickproject">
        <Slider {...settings}>
          <CustomSlide className="itm" index={1}>
            <div className="bg">
              <img
                src="./img/projects/img1.jpg"
                className="img-fluid"
                alt="Imageteam"
              />
            </div>
            <div className="desc">
              <div className="tag">Interior</div>
              <div className="name">Private Villa in St.Tropez</div>
            </div>
            <div className="icon">
              <span onClick={() => window.open("./detailcase", "_self")}>
                View Project
              </span>
            </div>
          </CustomSlide>
          <CustomSlide className="itm" index={2}>
            <div className="bg">
              <img
                src="./img/projects/img2.jpg"
                className="img-fluid"
                alt="Imageteam"
              />
            </div>
            <div className="desc">
              <div className="tag">Furniture</div>
              <div className="name">Restaurant Le Montana in Paris</div>
            </div>
            <div className="icon">
              <span onClick={() => window.open("./detailcase1", "_self")}>
                View Project
              </span>
            </div>
          </CustomSlide>
          <CustomSlide className="itm" index={3}>
            <div className="bg">
              <img
                src="./img/projects/img3.jpg"
                className="img-fluid"
                alt="Imageteam"
              />
            </div>
            <div className="desc">
              <div className="tag">Interior</div>
              <div className="name">Hotel du Roule in Paris</div>
            </div>
            <div className="icon">
              <span onClick={() => window.open("./detailcase2", "_self")}>
                View Project
              </span>
            </div>
          </CustomSlide>
          <CustomSlide className="itm" index={4}>
            <div className="bg">
              <img
                src="./img/projects/img4.jpg"
                className="img-fluid"
                alt="Imageteam"
              />
            </div>
            <div className="desc">
              <div className="tag">Furniture</div>
              <div className="name">Hotel Troyon in Paris</div>
            </div>
            <div className="icon">
              <span onClick={() => window.open("./detailCase3", "_self")}>
                View Project
              </span>
            </div>
          </CustomSlide>
          <CustomSlide className="itm" index={5}>
            <div className="bg">
              <img
                src="./img/projects/img5.jpg"
                className="img-fluid"
                alt="Imageteam"
              />
            </div>
            <div className="desc">
              <div className="tag">Furniture</div>
              <div className="name">Hotel Maison Fustemberg in Paris</div>
            </div>
            <div className="icon">
              <span onClick={() => window.open("./detailCase4", "_self")}>
                View Project
              </span>
            </div>
          </CustomSlide>
          {/* <CustomSlide className="itm" index={6}>
            <div className="bg">
              <img
                src="./img/projects/img6.jpg"
                className="img-fluid"
                alt="Imageteam"
              />
            </div>
            <div className="desc">
              <div className="tag">Furniture</div>
              <div className="name">Culturespaces in New York</div>
            </div>
            <div className="icon">
              <span onClick={() => window.open("./detailCase5", "_self")}>
                View Project
              </span>
            </div>
          </CustomSlide> */}
          <CustomSlide className="itm" index={7}>
            <div className="bg">
              <img
                src="./img/projects/img6.jpg"
                className="img-fluid"
                alt="Imageteam"
              />
            </div>
            <div className="desc">
              <div className="tag">Furniture</div>
              <div className="name">Hotel WO in Paris</div>
            </div>
            <div className="icon">
              <span onClick={() => window.open("./detailCase5", "_self")}>
                View Project
              </span>
            </div>
          </CustomSlide>
          {/*
          <CustomSlide className="itm" index={8}>
            <div className="bg">
              <img
                src="./img/projects/img8.jpg"
                className="img-fluid"
                alt="Imageteam"
              />
            </div>
            <div className="desc">
              <div className="tag">Furniture</div>
              <div className="name">Hotel Troyon in Paris</div>
            </div>
            <div className="icon">
              <span onClick={() => window.open("./detailCase7", "_self")}>
                View Project
              </span>
            </div>
          </CustomSlide>
          <CustomSlide className="itm" index={9}>
            <div className="bg">
              <img
                src="./img/projects/img9.jpg"
                className="img-fluid"
                alt="Imageteam"
              />
            </div>
            <div className="desc">
              <div className="tag">Furniture</div>
              <div className="name">Hotel Troyon in Paris</div>
            </div>
            <div className="icon">
              <span onClick={() => window.open("./detailCase8", "_self")}>
                View Project
              </span>
            </div>
          </CustomSlide>{" "}
          */}
        </Slider>
      </div>
    );
  }
}
