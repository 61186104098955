import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class CustomSlide extends Component {
  render() {
    const { index, ...props } = this.props;
    return <div {...props}></div>;
  }
}

export default class Responsive extends Component {
  render() {
    var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      autoplay: true,
      autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 5000,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 1600,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
      ],
    };
    return (
      <div className="slick slickclient">
        <Slider {...settings}>
          <CustomSlide className="logos" index={1}>
            <a className="link" href="https://chantaljais.com/" target="_blank">
              <img
                src="./img/ourclients/1.png"
                className="img-fluid"
                alt="Imageteam"
              />
            </a>
          </CustomSlide>

          <CustomSlide className="logos" index={2}>
            <a
              className="link"
              href="https://www.desallesflint.com/"
              target="_blank"
            >
              <img
                src="./img/ourclients/2.png"
                className="img-fluid"
                alt="Imageteam"
              />
            </a>
          </CustomSlide>

          <CustomSlide className="logos" index={3}>
            <a className="link" href="https://dhliberty.com/" target="_blank">
              <img
                src="./img/ourclients/3.png"
                className="img-fluid"
                alt="Imageteam"
              />
            </a>
          </CustomSlide>

          <CustomSlide className="logos" index={4}>
            <a
              className="link"
              href="http://www.ej-architecture.fr"
              target="_blank"
            >
              <img
                src="./img/ourclients/4.png"
                className="img-fluid"
                alt="Imageteam"
              />
            </a>
          </CustomSlide>

          <CustomSlide className="logos" index={5}>
            <a className="link" href="" target="_blank">
              <img
                src="./img/ourclients/5.png"
                className="img-fluid"
                alt="Imageteam"
              />
            </a>
          </CustomSlide>
          <CustomSlide className="logos" index={6}>
            <a className="link" href="" target="_blank">
              <img
                src="./img/ourclients/6.png"
                className="img-fluid"
                alt="Imageteam"
              />
            </a>
          </CustomSlide>
        </Slider>
      </div>
    );
  }
}
