import React from "react";

export default () => (
  <section className="container-fluid">
    <div className="row m-2-hor">
      <div className="col-md-6 pt-5">
        <div className="col-home">
          <div className="thumb">
            <img src="./img/home.jpg" className="img-fluid" alt="#" />
          </div>
        </div>
      </div>

      <div className="col-md-6">
        <div className="">
          <div className="col-home mt-md-0 mt-5">
            <div className="heading">
              Design & Decor Global Consultant and Turn Key Project Based in
              Switzerland.
            </div>
            <div className="content">
              DDGC specializes in interiors for FF&E as well as FIT OUT and
              provides materials and craftsmanship for bespoke furniture for
              luxury hotels, prestigious villas and public places such as
              restaurants, lounges and clubs. The company has worked alongside
              some of the most reputable and renowned architectural firms
              worldwide on their most prestigious projects, such as:
            </div>
            <ul className="list-home">
              <li>Studio Marco Piva , Milan</li>
              <li>Gilles & Boissier , Paris</li>
              <li>De Salles & Flint , UK</li>
              <li>Elsa Joseph Architecture , Paris</li>
              <li>
                100<sup>eme</sup> Etage Architectes , Paris{" "}
              </li>
              <li>Chantal Jaïs Architecture , Paris</li>
              <li>Kerylos Interieurs , Paris</li>
              <li>Artefak Architecture & Design , Paris</li>
              <li>DH Liberty – Arch. Dara Huang , London – UK</li>
              <li>Interlace Design – Arch. Yoshi Kida , Tokyo / Los Angeles</li>
              <li>DP Agence (Arch. Pascal Desprez) </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
);
