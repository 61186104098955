import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class CustomSlide extends Component {
  render() {
    const { index, ...props } = this.props;
    return <div {...props}></div>;
  }
}

export default class Responsive extends Component {
  render() {
    var settings = {
      dots: false,
      infinite: true,
      speed: 400,
      autoPlay: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      autoplay: true,
      autoplaySpeed: 3000,
      responsive: [
        {
          breakpoint: 5000,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 1600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
      ],
    };
    return (
      <div className="slick">
        <Slider {...settings}>
          <CustomSlide className="itm" index={1}>
            <div className="bg">
              <img
                src="./img/feature/img1.jpg"
                className="img-fluid"
                alt="Imageteam"
              />
            </div>
            <div className="desc">
              <div className="name">F.F. & E.</div>
              <div className="content">
                The excellent skills of our workers combined with our industrial
                organization allows us to achieve a perfect combination with the
                result to giving our customer a very good product at an
                attractive price. The right relationship between product and
                price that have given us the chance to list among our clients
                high-end hotel chains and private individuals.
              </div>
            </div>
          </CustomSlide>

          <CustomSlide className="itm" index={2}>
            <div className="bg">
              <img
                src="./img/feature/img2.jpg"
                className="img-fluid"
                alt="Imageteam"
              />
            </div>
            <div className="desc">
              <div className="name">Joinery</div>
              <div className="content">
                Our Technical department located at each of our individual
                production units give to our clients a very attentive service by
                designing each single piece of joinery and custom-made furniture
                in accordance to the instructions received from the Architect
                Studio in charge of the specific project. Not only executive
                drawings and production, but on-site installation is our
                priority.
              </div>
            </div>
          </CustomSlide>

          <CustomSlide className="itm" index={3}>
            <div className="bg">
              <img
                src="./img/feature/img3.jpg"
                className="img-fluid"
                alt="Imageteam"
              />
            </div>
            <div className="desc">
              <div className="name">FIT OUT</div>
              <div className="content">
                With more than 30-year family experience in buildings materials,
                we are able to find all kind of Fit Out at the most convenient
                price. We have our own installation team for all kind of
                specialities.
              </div>
            </div>
          </CustomSlide>

          <CustomSlide className="itm" index={4}>
            <div className="bg">
              <img
                src="./img/feature/img4.jpg"
                className="img-fluid"
                alt="Imageteam"
              />
            </div>
            <div className="desc">
              <div className="name">Decorative Lighting</div>
              <div className="content">
                With more than two decades of experience our workshops can
                design and produce all kind of decorative lightings in
                compliance with international standards but with a special focus
                on the artistic aspect. All kind of materials can be used and
                the result is unique artistic pieces with industrial precision.
              </div>
            </div>
          </CustomSlide>
        </Slider>
      </div>
    );
  }
}
