import React from "react";

export default () => (
  <footer className="container-fluid black_more">
    <div className="bg-footer">
      <img src="./img/footer-img.png" alt="bg-footer" />
    </div>
    <div className="row m-2-hor">
      <div className="col-md-4">
        <div className="footer-col">
          <div className="heading">
            <h2>About Us</h2>
          </div>
          <div className="content">
            <p>
              Consultancy and execution on design of projects in the hospitality
              sector, villas and private apartments both for furniture
              furnishings (F.F. & E.) and for joinery, carpentry, building
              materials (Fit Out). Consultancy freelance. Expert in luxury
              residential and hospitality turnkey projects ,partial or full
              refurbishment. Proven ability to work with top level clients for
              multi-million dollar projects... And more! Implementation of
              aeraulic systems with application of the &nbsp;
              <a
                className="link-call"
                href="https://www.nwpinternational.com/"
                target="_blank"
              >
                Continuous Active Sanitization®️
              </a>
              &nbsp; protocol, preparatory for obtaining the Air Sanitary
              Quality certificate (IAQ-ASE ®️)
            </p>
          </div>
          <div
            className="link-call"
            onClick={() =>
              window.open(
                "mailto:designdecorglobalconsultant@gmail.com",
                "_self"
              )
            }
          >
            designdecorglobalconsultant@gmail.com
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="footer-col">
          <div className="heading">Follow Us</div>
          <div className="content">
            <p>Explore the gallery of our projects and our experience.</p>
            <div className="socialicon">
              <span className="shine"></span>
              <i
                onClick={() =>
                  window.open("//linkedin.com/in/fabrizio-chiodini", "_blank")
                }
                className="fa fa-linkedin"
              ></i>
            </div>
            <div className="socialicon">
              <span className="shine"></span>
              <i
                onClick={() =>
                  window.open(
                    "https://instagram.com/designdecorglobalconsultant",
                    "_blank"
                  )
                }
                className="fa fa-instagram"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="subfooter">
      <div className="row m-2-hor">
        <div className="col-md-6">
          <div className="content">
            © Copyrights {new Date().getFullYear()} Design Decor Global
            Consultant All rights reserved.
          </div>
        </div>
      </div>
    </div>
  </footer>
);

//  <div className='col-md-2'>
//         <div className='footer-col'>
//           <div className='heading'>
//             Usefull link
//           </div>
//           <div className='content'>
//              <div className='link'>Frequently Asked</div>
//              <div className='link'>Terms & Conditions</div>
//              <div className='link'>Help Center</div>
//              <div className='link'>Contact Us</div>
//           </div>
//         </div>
//       </div>

//  <div className='col-md-2'>
//         <div className='footer-col'>
//           <div className='heading'>
//             Features
//           </div>
//           <div className='content'>
//             <div className='link'>Career</div>
//             <div className='link'>Brand Identity</div>
//             <div className='link'>Investment</div>
//             <div className='link'>Agency Patner</div>
//           </div>
//         </div>
//       </div>
