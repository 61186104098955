import React from "react";
import Slider from "react-animated-slider";

function CustomSlider(props) {
  let counter = 0;
  const content = [];
  let noMoreFiles = false;
  while (!noMoreFiles) {
    let imageContent = { image: `${props.dir} (${counter}).jpg` };
    counter++;
    content.push(imageContent);
    if (counter == props.counterLimit) {
      noMoreFiles = true;
    }
  }
  return (
    <Slider className="slider-wrapper" autoplay={3000}>
      {content.map((item, index) => (
        <div
          key={index}
          className="slider-content"
          style={{
            background: `url('${item.image}') no-repeat center center`,
            height: "95%",
          }}
        ></div>
      ))}
    </Slider>
  );
}
export default (props) => (
  <CustomSlider
    counterLimit={props.counterLimit}
    dir={props.dir}
  ></CustomSlider>
);
