import React from "react";

export default () => (
  <section className="container-fluid pt-0" id="listcomplish">
    <div className="linebg">
      <img src="./img/linebg.png" className="img-fluid" alt="Imageteam" />
    </div>
    <div className="row m-2-hor">
      <div className="col-12 mb-5">
        <div className="heading">History Cases</div>
      </div>
      <div className="col-12">
        <div className="listcomplish">
          <span className="year">2022 (running project )</span>
          <span className="content">
            74 rooms and public areas. In collaboration with Hipret Suisse SA
          </span>
          <span className="detail">Hotel Amiot – Paris</span>
        </div>
        <div className="listcomplish">
          <span className="year">February 2022</span>
          <span className="content">
            F.F. & E. - Bronze Framing, Lighting and details
          </span>
          <span className="detail">Culturespaces – New York</span>
        </div>
        <div
          className="listcomplish link"
          onClick={() => window.open("./detailcase1", "_self")}
        >
          <span className="year">January 2022</span>
          <span className="content">F.F. & E. and Fit Out</span>
          <span className="detail">Restaurant Le Montana – Paris</span>
        </div>
        <div className="listcomplish">
          <span className="year">January 2022</span>
          <span className="content">Artworks and decorative lighting</span>
          <span className="detail">Hotel Maison Fustemberg – Paris</span>
        </div>
        <div className="listcomplish">
          <span className="year">2021 (TBC by March 2022)</span>
          <span className="content">16 rooms and public areas</span>
          <span className="detail">Hotel Le Saint – Neuilly</span>
        </div>
        <div className="listcomplish">
          <span className="year">2021 (running project)</span>
          <span className="content">33 rooms and public areas</span>
          <span className="detail">Hotel De France – Versailles</span>
        </div>
        <div className="listcomplish">
          <span className="year">2021 (running project)</span>
          <span className="content">
            All Joinery, exterior balcony and gates in wrought iron. In
            collaboration with Hipret Suisse SA
          </span>
          <span className="detail">Private Villa in Kobe – Japan</span>
        </div>

        <div className="listcomplish">
          <span className="year">2021 (Running project)</span>
          <span className="content">
            51 rooms and public areas - Mock up rooms
          </span>
          <span className="detail">Hotel Napoleon - Fontainebleu</span>
        </div>

        <div className="listcomplish">
          <span className="year">November 2021</span>
          <span className="content">F.F. & E.</span>
          <span className="detail">
            Private apartment Boulevard St. Germain – Paris
          </span>
        </div>
        <div
          className="listcomplish link"
          onClick={() => window.open("./detailcase5", "_self")}
        >
          <span className="year">November 2021</span>
          <span className="content">30 rooms and public areas</span>
          <span className="detail">Hotel WO – Paris</span>
        </div>
        <div
          className="listcomplish link"
          onClick={() => window.open("./detailcase3", "_self")}
        >
          <span className="year">October 2021</span>
          <span className="content">30 rooms and public areas</span>
          <span className="detail">Hotel Troyon – Paris</span>
        </div>
        <div
          className="listcomplish link"
          onClick={() => window.open("./detailcase2", "_self")}
        >
          <span className="year">October 2021</span>
          <span className="content">34 rooms and public areas</span>
          <span className="detail">Hotel Du Roule – Paris</span>
        </div>
        <div
          className="listcomplish link"
          onClick={() => window.open("./detailcase", "_self")}
        >
          <span className="year">September 2021</span>
          <span className="content">All joinery and F.F. & E.</span>
          <span className="detail">Private Villa in St. Tropez</span>
        </div>
      </div>
    </div>
  </section>
);
